
import http from '@/utils/http'
import { defineComponent, onMounted, ref } from 'vue'
import { format_date } from '../../../utils/index'
import crypto from 'crypto-js'
import { message } from 'ant-design-vue'

const columns = [
  {
    title: '用户名',
    dataIndex: 'Username',
    key: 'app_key',
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'Createtime',
    slots: { customRender: 'Createtime' },
    key: 'app_key',
    align: 'center'
  },
  {
    title: '所属管理员',
    dataIndex: 'Inviter',
    slots: { customRender: 'Inviter' },
    key: 'Inviter',
    align: 'center'
  },
  {
    title: '用户类型',
    dataIndex: 'Type',
    key: 'status',
    slots: { customRender: 'Type' },
    align: 'center'
  },
  {
    title: '状态',
    dataIndex: 'Status',
    key: 'status',
    slots: { customRender: 'Status' },
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: '',
    key: '',
    slots: { customRender: 'edit' },
    align: 'center'
  }
]

interface Value {
  value?: string;
  label?: string;
}

export default defineComponent({
  name: 'DataOverview',
  setup () {
    const total = ref(0)
    const currentPage = ref(1)
    const dataSource = ref([])
    const iconLoading = ref(false)
    const visible = ref(false)
    const addUserVisible = ref(false)
    const form = ref({ app_key: '' })
    const modalTitle = ref('修改登陆密码')
    const pwd = ref('')
    const curUserName = ref('')
    const adminList = ref([])
    const options = ref<any>([])
    const modalData = ref({u: '', p: '',})
    const supper = ref(sessionStorage.getItem('supper') != null ? JSON.parse(sessionStorage.getItem('supper')!) : false)
    const match = ref('')
    const userType = ref<number>(1) // 0管理员 1普通用户
  
    const paginationChange = (page: any) => {
      currentPage.value = page
      getList()
    }

    const getAdminList = () => {
      http(`/tokenapi/user_list?type=0&page=${currentPage.value}`, {
        method: 'get',
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          adminList.value = res.data.list
          res.data.list.forEach((element: any) => {
            options.value?.push({
              value: element.Username,
              label: element.Username
            } )
          });
          getList()
        }
      })
    }

    const getList = () => {
      iconLoading.value = true;
      http(`/tokenapi/user_list?username=${sessionStorage.getItem('username')}&page=${currentPage.value}&match=${match.value}&type=${userType.value}`, {
        method: 'get',
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          dataSource.value = res.data.list
          total.value = res.data.total
        }
        iconLoading.value = false;
      })
    }

    const editPwd = (type: string, userName: string) => {
      if (type === 'password') {
        modalTitle.value = '修改登陆密码'
      } else {
        modalTitle.value = '修改验证码'
      }
      curUserName.value = userName
      visible.value = true
    }

    const handleOk = () => {
      if (pwd.value === '') {
        message.error('不能为空！')
        return
      }
      let key = ''
      modalTitle.value === '修改登陆密码' ? key = 'password' : key = '2f'
      http(`/tokenapi/user`, {
        method: 'post',
        body: JSON.stringify({
          username: curUserName.value,
          [key]: crypto.SHA1(pwd.value).toString(crypto.enc.Hex).toLocaleUpperCase()
        }),
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          pwd.value = ''
          visible.value = false
        }
        message.info(res.msg)
      })
    }

    const statusType = (type: Number) => {
      if (type == 0) {
        return '未设置'
      } else if (type == 1) {
        return '未校验'
      } else if (type == 2) {
        return '已校验'
      } else if (type == 3) {
        return '锁住'
      } else {
        return ''
      }
    }

    const lock = (record: any) => {
      http(`/tokenapi/user`, {
        method: 'post',
        body: JSON.stringify({
          username: record.Username,
          status: 3
        }),
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          getList()
        }
        message.info(res.msg)
      })
    }

    const unlock = (record: any) => {
      http(`/tokenapi/user`, {
        method: 'post',
        body: JSON.stringify({
          username: record.Username,
          status: 1
        }),
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          getList()
        }
        message.info(res.msg)
      })
    }

    const handleChangeInviter = (value: Value, record: any) => {
      http(`/tokenapi/move_user?u=${record.Username}&to=${value.value}`, {
        method: 'get',
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          getList()
        }
        message.info(res.msg)
      })
    }

    const addUser = () => {
      addUserVisible.value = true
      modalData.value = {u: '', p: ''}
    }

    const addUserHandleOk = () => {
      http(`/tokenapi/new_user`, {
        method: 'post',
        body: JSON.stringify({
          u: modalData.value.u,
          p: crypto.SHA1(modalData.value.p).toString()
        }),
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          addUserVisible.value = false
          getList()
        }
        message.info(res.msg)
      })
    }

    const userTypeChange = () => {
      getList()
    }

    onMounted(() => {
      getAdminList()
    })

    return {
      columns,
      total,
      currentPage,
      paginationChange,
      dataSource,
      iconLoading,
      form,
      format_date,
      visible,
      editPwd,
      modalTitle,
      pwd,
      handleOk,
      statusType,
      lock,
      unlock,
      options,
      handleChangeInviter,
      modalData,
      addUserVisible,
      addUser,
      addUserHandleOk,
      supper,
      getList,
      match,
      userType,
      userTypeChange
    }
  }
})
