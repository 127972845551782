<template>
  <div class="app_key">
    <div class="search-bar">
      <span v-if="supper">
        <a-button type="primary" @click="addUser">新增</a-button>
        <a-select
          ref="select"
          v-model:value="userType"
          style="width: 120px"
          @change="userTypeChange"
        >
          <a-select-option :value="0">管理员</a-select-option>
          <a-select-option :value="1">普通用户</a-select-option>
        </a-select>
      </span>
      <span >
        <a-input v-model:value="match" placeholder="用户名"/>
        <a-button type="primary" @click="getList">搜索</a-button>
      </span>
    </div>
    <a-table :dataSource="dataSource" :columns="columns" :row-key="record => record.Id" :size="'small'" :pagination="false" :loading="iconLoading">
      <template #Type="{ record }">
        <span>{{ record.Type.toString() === '0' ? '管理员' : '普通用户' }}</span>
      </template>
      <template #Status="{ record }">
        <span>{{ statusType(record.Status) }}</span>
      </template>
      <template #Createtime="{ record }">
        <span>{{ format_date(new Date(Number(record.Createtime + '000')), 'YYYY-MM-DD hh:mm:ss') }}</span>
      </template>
      <template #Inviter="{ record }" v-if="supper">
        <a-select
          :value="{'value' : record.Inviter}"
          label-in-value
          style="width: 120px"
          :options="options"
          @change="(v) => handleChangeInviter(v, record)"
        >
        </a-select>
      </template>
      <template #edit="{ record }">
        <a-button type="link" @click="editPwd('password', record.Username)">修改登陆密码</a-button>
        <a-button type="link" @click="editPwd('2f', record.Username)">修改验证码</a-button>
        <a-popconfirm
          title="确定锁住该账号吗？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="lock(record)"
          v-if="record.Status != 3"
        >
          <a-button type="link">锁住</a-button>
        </a-popconfirm>
        <a-popconfirm
          title="确定解锁该账号吗？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="unlock(record)"
          v-if="record.Status == 3"
        >
          <a-button type="link">解锁</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-pagination
      :total="total"
      :show-total="total => `总共 ${total} 条`"
      :page-size="100"
      :size="'small'"
      v-model:current="currentPage"
      @change="paginationChange"
    />
    <a-modal v-model:visible="visible" :title="modalTitle" @ok="handleOk" okText="保存" cancelText="取消">
      <div>
        <span v-if="modalTitle === '修改登陆密码'">登陆密码：</span>
        <span v-else>验证码：</span>
        <a-input style="display: inline; width: 85%" v-model:value="pwd" />
      </div>
    </a-modal>  
    <a-modal v-model:visible="addUserVisible" title="新增用户" @ok="addUserHandleOk" okText="保存" cancelText="取消">
      <div style="margin-bottom: 10px">
        <span style="display: inline-block; width: 15%">用户名:</span>
        <a-input style="display: inline; width: 85%" v-model:value="modalData.u" />
      </div>
      <div style="margin-bottom: 10px">
        <span style="display: inline-block; width: 15%">密码:</span>
        <a-input style="display: inline; width: 85%" v-model:value="modalData.p" />
      </div>
    </a-modal> 
  </div>
</template>

<script lang="ts">
import http from '@/utils/http'
import { defineComponent, onMounted, ref } from 'vue'
import { format_date } from '../../../utils/index'
import crypto from 'crypto-js'
import { message } from 'ant-design-vue'

const columns = [
  {
    title: '用户名',
    dataIndex: 'Username',
    key: 'app_key',
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'Createtime',
    slots: { customRender: 'Createtime' },
    key: 'app_key',
    align: 'center'
  },
  {
    title: '所属管理员',
    dataIndex: 'Inviter',
    slots: { customRender: 'Inviter' },
    key: 'Inviter',
    align: 'center'
  },
  {
    title: '用户类型',
    dataIndex: 'Type',
    key: 'status',
    slots: { customRender: 'Type' },
    align: 'center'
  },
  {
    title: '状态',
    dataIndex: 'Status',
    key: 'status',
    slots: { customRender: 'Status' },
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: '',
    key: '',
    slots: { customRender: 'edit' },
    align: 'center'
  }
]

interface Value {
  value?: string;
  label?: string;
}

export default defineComponent({
  name: 'DataOverview',
  setup () {
    const total = ref(0)
    const currentPage = ref(1)
    const dataSource = ref([])
    const iconLoading = ref(false)
    const visible = ref(false)
    const addUserVisible = ref(false)
    const form = ref({ app_key: '' })
    const modalTitle = ref('修改登陆密码')
    const pwd = ref('')
    const curUserName = ref('')
    const adminList = ref([])
    const options = ref<any>([])
    const modalData = ref({u: '', p: '',})
    const supper = ref(sessionStorage.getItem('supper') != null ? JSON.parse(sessionStorage.getItem('supper')!) : false)
    const match = ref('')
    const userType = ref<number>(1) // 0管理员 1普通用户
  
    const paginationChange = (page: any) => {
      currentPage.value = page
      getList()
    }

    const getAdminList = () => {
      http(`/tokenapi/user_list?type=0&page=${currentPage.value}`, {
        method: 'get',
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          adminList.value = res.data.list
          res.data.list.forEach((element: any) => {
            options.value?.push({
              value: element.Username,
              label: element.Username
            } )
          });
          getList()
        }
      })
    }

    const getList = () => {
      iconLoading.value = true;
      http(`/tokenapi/user_list?username=${sessionStorage.getItem('username')}&page=${currentPage.value}&match=${match.value}&type=${userType.value}`, {
        method: 'get',
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          dataSource.value = res.data.list
          total.value = res.data.total
        }
        iconLoading.value = false;
      })
    }

    const editPwd = (type: string, userName: string) => {
      if (type === 'password') {
        modalTitle.value = '修改登陆密码'
      } else {
        modalTitle.value = '修改验证码'
      }
      curUserName.value = userName
      visible.value = true
    }

    const handleOk = () => {
      if (pwd.value === '') {
        message.error('不能为空！')
        return
      }
      let key = ''
      modalTitle.value === '修改登陆密码' ? key = 'password' : key = '2f'
      http(`/tokenapi/user`, {
        method: 'post',
        body: JSON.stringify({
          username: curUserName.value,
          [key]: crypto.SHA1(pwd.value).toString(crypto.enc.Hex).toLocaleUpperCase()
        }),
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          pwd.value = ''
          visible.value = false
        }
        message.info(res.msg)
      })
    }

    const statusType = (type: Number) => {
      if (type == 0) {
        return '未设置'
      } else if (type == 1) {
        return '未校验'
      } else if (type == 2) {
        return '已校验'
      } else if (type == 3) {
        return '锁住'
      } else {
        return ''
      }
    }

    const lock = (record: any) => {
      http(`/tokenapi/user`, {
        method: 'post',
        body: JSON.stringify({
          username: record.Username,
          status: 3
        }),
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          getList()
        }
        message.info(res.msg)
      })
    }

    const unlock = (record: any) => {
      http(`/tokenapi/user`, {
        method: 'post',
        body: JSON.stringify({
          username: record.Username,
          status: 1
        }),
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          getList()
        }
        message.info(res.msg)
      })
    }

    const handleChangeInviter = (value: Value, record: any) => {
      http(`/tokenapi/move_user?u=${record.Username}&to=${value.value}`, {
        method: 'get',
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          getList()
        }
        message.info(res.msg)
      })
    }

    const addUser = () => {
      addUserVisible.value = true
      modalData.value = {u: '', p: ''}
    }

    const addUserHandleOk = () => {
      http(`/tokenapi/new_user`, {
        method: 'post',
        body: JSON.stringify({
          u: modalData.value.u,
          p: crypto.SHA1(modalData.value.p).toString()
        }),
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          addUserVisible.value = false
          getList()
        }
        message.info(res.msg)
      })
    }

    const userTypeChange = () => {
      getList()
    }

    onMounted(() => {
      getAdminList()
    })

    return {
      columns,
      total,
      currentPage,
      paginationChange,
      dataSource,
      iconLoading,
      form,
      format_date,
      visible,
      editPwd,
      modalTitle,
      pwd,
      handleOk,
      statusType,
      lock,
      unlock,
      options,
      handleChangeInviter,
      modalData,
      addUserVisible,
      addUser,
      addUserHandleOk,
      supper,
      getList,
      match,
      userType,
      userTypeChange
    }
  }
})
</script>

<style lang="scss">
.app_key {
  .search-bar {
    height: 40px;
    margin-bottom: 15px;

    .label {
      width: 40px;
      display: inline;

      .anticon {
        margin: 0 3px;
      }
    }

    .ant-input {
      display: inline;
      width: 250px;
      margin: 0 10px;
    }

    .ant-btn {
      margin: 0 10px;
    }
  }

  .ant-pagination {
    margin: 20px 0;
    display: flex;
    justify-content: flex-end;
  }
}

.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>
